/* Theme icons */
.theme-icons {
  position: relative;
}

.theme-icons button {
  background: none;
  border: none;

  cursor: pointer;
  font-size: 1rem;
  vertical-align: middle;
}

.theme-icons,
.moon-icon,
.sun-icon:hover {
  color: var(--general-design-color);
}

.theme-icons,
.moon-icon,
.sun-icon {
  color: var(--general-text);
}

.theme-icons .sun-icon {
  font-size: 1.3rem;
}

/**********************************************/

/* Hide icon when click on theme */
.theme-icons .hide {
  display: none;
}

/**********************************************/