/* Introduce */
header {
  background-color: var(--light-theme-bg);
  padding-bottom: 5rem;
}

.introduce {
  display: grid;
  grid-template-areas: "a b"
                       "c c";
  place-items: center;

  padding-top: 5rem;
}

.introduce .text-wrapper {
  max-width: 25rem;
  
  border-left: 5px solid var(--general-design-color);
  color: var(--navbar-title-text);

  grid-area: a;
}

.introduce .title {
  margin-bottom: 1rem;

  font-size: clamp(.9rem, 5vw, 1.3rem);
  text-align: center;
}

.introduce span {
  color: #0966b1;
}

.introduce .about-me {
  text-align: center;
  padding-left: 1rem;
}

.introduce .my-image {
  max-width: clamp(10rem, 30vw, 20rem);

  border-radius: 34% 66% 69% 31% / 30% 30% 70% 70%;
  box-shadow: 1px 1px 10px var(--general-text);

  grid-area: b;
}
/**********************************************/

/* Icons */
.introduce .tech-lang-wrapper {
  margin-top: 10rem;

  color: var(--general-text);
  text-align: center;

  grid-area: c;
}

.introduce .tech-lang-wrapper .icons {
  display: flex;
  justify-content: center;
    -webkit-justify-content: center;
  align-items: center;
    -webkit-align-items: center;
  flex-wrap: wrap;
    -webkit-flex-wrap: wrap;

  gap: 4.5rem;
}

.introduce .icons-title {
  text-align: center;
  font-size: .8rem;
  color: var(--navbar-title-text);
}

.introduce .icons .icon {
  vertical-align: middle;
}
/**********************************************/

/* Media Query */
@media (max-width: 765px) {
  .introduce {
    grid-template-areas: "a"
                         "b"
                         "c";
    gap: 3.5rem;
    place-items: center;
  }

  .introduce .tech-lang-wrapper {
    margin-top: 2rem;
  }

  .introduce .tech-lang-wrapper .icons {
    gap: 2rem;
  }
}