/* Web projects wrapper */
.my-projects {
  background-color: var(--light-theme-bg);
  padding-block: 5rem;
}

.web-projects-wrapper {
  display: grid;
  justify-content: center;
    -webkit-justify-content: center;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  margin-bottom: 2rem;
}

/* title */
.my-projects .title {
  margin-bottom: 2rem;

  text-align: center;
  font-size: 1.3rem;
  color: var(--general-text);

  display: flex;
  justify-content: center;
    -webkit-justify-content: center;
  align-items: center;
    -webkit-align-items: center;
  gap: 1rem;
}

.my-projects .title-icon {
  font-size: 2.2rem;
}

/**********************************************/

/* cards */
.my-projects .vympy-art-card,
.my-projects .pod-tlakem-card,
.my-projects .remodelto,
.my-projects .zlute-leseni {
  display: flex;
  justify-content: center;
    -webkit-justify-content: center;
  align-items: center;
    -webkit-align-items: center;
  gap: 3.5rem;

  text-align: justify;

  padding: 2rem;
  background-color: var(--second-light-theme-bg);
  box-shadow: 1px 1px 15px var(--general-text);
}

.my-projects .vympy-art-img,
.my-projects .pod-tlakem-img,
.my-projects .remodelto-img,
.my-projects .zlute-leseni-img {
  width: clamp(4rem, 14vw, 8rem);
  vertical-align: middle;
  border-radius: 15px;
}

.links-demo-code {
  display: flex;

  text-align: center;
  padding-top: 1rem;
}

.links-demo-code a {
  color: #0966b1;
}

.my-projects .links-demo-code .icon {
  font-size: 1.5rem;
  color: var(--general-text);
  vertical-align: middle;

  margin-right: .5rem;
}

.my-projects .links-demo-code a:not(:last-child) {
  margin-right: .5rem;
}

/* description */
.my-projects .description {
  font-size: .8rem;
  color: var(--general-text);
}

.my-projects .description h3 {
  font-size: 1.2rem;
}

.my-projects .description>* {
  margin-bottom: 1rem;
}

.my-projects .description span {
  color: #0966b1;
}

/**********************************************/

/* Media Query */
@media (max-width: 1000px) {
  .web-projects-wrapper {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 650px) {
  .my-projects .vympy-art-card,
  .my-projects .pod-tlakem-card,
  .my-projects .remodelto,
  .my-projects .zlute-leseni {
    flex-direction: column;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
    gap: 1.5rem;

    text-align: center;
  }
}