/* Footer */
footer {
  padding: 2rem 0;
  
  font-size: .9rem;
  color: white;
  
  background: rgba(0, 0, 0, 0.9);
  border-top: 1px solid var(--general-text);
}

footer .footer-wrapper {
  text-align: center;
}

footer .footer-wrapper > * {
  margin-bottom: .5rem;
}

footer .github {
  margin-right: .3rem;
}

footer .contact-email,
footer .contact-mobile {
  display: block;
}

footer a {
  color: var(--general-design-color);
}

footer .text-icons,
footer .link-icons {
  display: inline;
  font-size: .7rem;
}

footer .icon {
  color: white;
  font-size: 1.3rem;
  vertical-align: middle;
}
/**********************************************/
